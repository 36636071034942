.article-teasers {
  max-width: rem-calc(1120);
  margin: 0 auto;
  padding-left: rem-calc(8);
  padding-right: rem-calc(8);

  @include breakpoint-down(tablet) {
    max-width: rem-calc(640);
    padding-left: rem-calc(16);
    padding-right: rem-calc(16);
  }

  @include breakpoint-down(mobile) {
    padding-left: rem-calc(8);
    padding-right: rem-calc(8);
  }

  h2 {
    margin-left: 0;
  }

  &__desktop {
    display: grid;
    grid-template-columns: repeat(3, calc(33.333% - spacing(1)));
    &--two-up {
      grid-template-columns: repeat(2, calc(50% - spacing(1)));
    }
    gap: spacing(2);
    @include breakpoint-down(mobile) {
      display: none;
    }
    &--no-carousel {
      display: grid;
      > div {
        width: 100%;
      }
      @include breakpoint-down(mobile) {
        display: grid;
        grid-template-columns: 1fr;
        padding-right: rem-calc(8);
        padding-left: rem-calc(8);
      }
    }
  }

  &__mobile-carousel {
    display: none;
    @include breakpoint-down(mobile) {
      display: block;
    }
  }

  &__desktop-carousel {
    display: block;
  }

  &__carousel {
    .carousel__pagination {
      margin: 0 0 rem-calc(24) 0;
      padding: 0;
    }

    .carousel__slide {
      > div {
        height: 100%;
        padding: 0 spacing(1);
        width: 100%;
      }
    }

    .cmp-teaser, .cmp-teaser__link, .cmp-teaser__container {
      height: 100%;
    }

    .carousel__pagination-button {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $color-background-white;
      border: rem-calc(1) solid $color-border-grey-400;
      border-radius: 50%;
      cursor: pointer;
      height: rem-calc(18);
      margin-top: spacing(1);
      margin-inline: spacing(0.5);
      opacity: 1;
      padding: 0;
      width: rem-calc(18);

      &::after {
        background-color: $color-background-white;
        border-radius: rem-calc(10);
        content: '';
        height: rem-calc(10);
        width: rem-calc(10);
      }

      &--active {
        border-color: $color-border-black;
        &::after {
          background-color: $color-background-black;
        }
      }
    }
  }
}
