.cmp-text {
  color: $color-text-grey;
  font-family: "TCCC-UnityText-Regular";
  font-size: rem-calc(16);
  font-weight: normal;
  line-height: 150%;
  margin: spacing(1.5) 0 0 0;

  @include breakpoint-down(mobile) {
    font-size: rem-calc(14);
  }

  b {
    font-family: "TCCC-UnityText-Black";
    font-weight: bold;
  }

  p a {
    border: rem-calc(2) solid transparent;
    border-radius: rem-calc(14);
    color: $color-accent;
    font-family: "TCCC-UnityText-Black";
    text-decoration: underline;

    @include breakpoint-down(mobile) {
      font-size: rem-calc(14);
    }

    &:hover {
      color: $color-accent-darker;
      text-decoration: underline;
      text-decoration-thickness: rem-calc(2);
      text-decoration-color: $color-accent-darker;
      text-decoration-style: solid;
    }
    &:focus {
      text-decoration: underline;
      border-color: $color-accent;
      outline: none;
      text-decoration-thickness: rem-calc(2);
      text-decoration-color: $color-accent;
      text-decoration-style: solid;
    }
  }

  blockquote {
    font-family: "TCCC-UnityCondensed-Bold";
    font-size: rem-calc(64);
    line-height: 110%;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;

    @include breakpoint-down(mobile) {
      line-height: rem-calc(48);
      font-size: rem-calc(48);
    }
  }

  ul li::marker {
    content: '•  ';
  }
}

.text {
  &.paragraph-small {
    > .cmp-text {
      margin: 0 auto;
      width: 33.33%;
    }
  }

  &.paragraph-medium {
    > .cmp-text {
      margin: 0 auto;
      width: 83.33%;
    }
  }

  &.paragraph-large {
    > .cmp-text {
      margin: 0 auto;
      width: 100%;
    }
  }

  &.full-width-on-mobile {
    > .cmp-text {
      @include breakpoint-down(mobile) {
        width: 100%;
      }
    }
  }

  &.body-large-bold {
    p,
    a,
    b {
      font-family: "TCCC-UnityText-Black";
      font-size: rem-calc(24);
      line-height: 140%;

      @include breakpoint-down(mobile) {
        font-size: rem-calc(20);
      }
    }
  }

  em {
    font-family: "TCCC-UnityText-Black";
    font-size: rem-calc(16);
    font-style: normal;
    // The RTC automatically includes an i element with an em
    i {
      font-style: normal;
    }
  }

  &.eyebrow {
    p {
      font-family: "TCCC-UnityText-Black";
      font-size: rem-calc(12);
    }
  }

  &.caption {
    p {
      font-family: "TCCC-UnityText-Regular";
      font-size: rem-calc(12);
    }
    a {
      font-family: "TCCC-UnityText-Bold";
      font-size: rem-calc(12);
    }
    b {
      font-family: "TCCC-UnityText-Black";
      font-weight: 800;
    }
  }
}

#article-date {
  p {
    text-transform: uppercase;
    font-size: rem-calc(16);
    color: $color-text-grey;
    text-align: center;
  }
}
