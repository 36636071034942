.header {
  background-color: $color-background-black;
  width: 100%;
  padding: spacing(3);

  > .cmp-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: rem-calc(1120);
    margin: 0 auto;

    @include breakpoint-down(tablet) {
      justify-content: space-between;
    }
  }

  &__search-icon {
    margin-left: auto;
    a {
      display: inline-block;
      background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2224%22%20height%3D%2225%22%20viewBox%3D%220%200%2024%2025%22%20fill%3D%22none%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%3Cpath%20d%3D%22M10.112%2016.7921C13.4876%2016.7921%2016.224%2014.0557%2016.224%2010.6801C16.224%207.30455%2013.4876%204.56812%2010.112%204.56812C6.73645%204.56812%204%207.30455%204%2010.6801C4%2014.0557%206.73645%2016.7921%2010.112%2016.7921Z%22%20stroke%3D%22white%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22/%3E%3Cpath%20d%3D%22M14.4316%2015L19.9996%2020.568%22%20stroke%3D%22white%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22/%3E%3C/svg%3E');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      height: rem-calc(20);
      width: rem-calc(20);
      margin: 0;
    }
  }

  &__hamburger-button {
    cursor: pointer;
    background: none;
    display: none;
    border: none;
    padding: 0;
    margin: spacing(0.5) spacing(2) 0 spacing(0.5);

    @include breakpoint-down(tablet) {
      display: block;
    }
  }

  &__hamburger-bar {
    width: rem-calc(20);
    height: rem-calc(3);
    background-color: $color-background-white;
    margin: spacing(0.5) 0;
    transition: 0.4s;
    border-radius: rem-calc(5);
  }

  &__close-button {
    position: absolute;
    display: none;
    @include breakpoint-down(tablet) {
      display: block;
    }
    right: spacing(3);
    top: spacing(5);
    z-index: 1000;
    border: none;
    font-family: 'icomoon';
    background: $color-background-black;
    color: $color-text-white;
    cursor: pointer;
  }

  &__navigation {
    width: 100%;
    margin-left: spacing(4);

    > .cmp-text {
      display: flex;
      flex-direction: row;
      @include breakpoint-down(tablet) {
        margin-left: 0;
        padding-left: spacing(6);
        padding-top: spacing(10);
        position: fixed;
        background: $color-background-black;
        flex-direction: column;
        height: 100vh;
        width: 100vw;
        left: 0;
        top: 0;
        display: none;
        z-index: 1000;
      }
    }

    @include breakpoint-down(tablet) {
      display: none;
    }

    &-visible {
      display: block;
      > .cmp-text {
        @include breakpoint-down(tablet) {
          display: flex;
        }
      }
    }
  }

  .cmp-text {
    width: 100%;
    margin: 0;
    margin-top: spacing(0.5);
    a {
      position: relative;
      color: $color-text-white;
      font-family: 'TCCC-UnityText-Regular';
      text-decoration: none;
      font-size: rem-calc(16);
      font-style: normal;
      font-weight: 800;
      margin-right: spacing(4);
      &.selected {
        pointer-events: none;
        &:after {
        @include rainbow-border;
        top: unset;
          bottom: rem-calc(-8);
        }
      }
      @include breakpoint-down(tablet) {
        font-family: 'TCCC-UnityText-Regular';
        font-size: rem-calc(24);
        font-weight: 800;
      }
      &:hover,
      &:focus,
      &:focus-visible {
        text-decoration: none;
        color: $color-text-white;
        border-color: transparent;
        box-shadow: none;
        &:after {
          content: '';
          position: absolute;
          left: 0;
          width: 100%;
          height: rem-calc(4);
          background: $color-background-white;
          top: unset;
          bottom: rem-calc(-8);
        }
      }
    }
  }
  .image {
    width: fit-content;
    margin-right: spacing(8);
    > .cmp-image {
      margin: 0;
    }
  }
}
