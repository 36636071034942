.footer {
  background: $color-background-white;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: spacing(8) spacing(1);

  @include breakpoint-down(tablet) {
    padding: spacing(7) spacing(1);
  }

  > .cmp-container {
    max-width: rem-calc(1120);
    width: calc(100% - spacing(4));
    margin: 0 16px;
    position: relative;

    &::after {
      content: '';
      display: block;
      width: 100%;
      max-width: rem-calc(1120);
      @include rainbow-border;
      position: absolute;
      left: 0;
      top: 0;
    }
  }

  img {
    margin: spacing(4) auto 0 auto;
    max-width: 150px;
  }

  .cmp-text {
    justify-content: center;
    display: flex;
    flex-direction: row;
    margin: 0;
    font-family: 'TCCC-UnityText-Regular';
    white-space: normal;
    flex-wrap: wrap;
    p {
      width: auto;
    }
    a {
      color: $color-text-black;
      text-decoration: underline;
      &:hover,
      &:focus,
      &:focus-visible {
        border-color: transparent;
        text-decoration: none;
        color: $color-text-blue-600;
      }
      &:active {
        color: $colors-blue-700;
      }
    }
  }
  &__copyright {
    font-size: rem-calc(14);
    a {
      font-weight: 800;
    }
  }
  &__secondary-links {
    margin-bottom: spacing(1);
    p {
      margin: 0;
      padding: 0;
      font-size: rem-calc(12);
      font-weight: 700;
      &:not(:last-child)::after {
        content: '\00A0|\00A0';
        font-weight: 400;
      }
    }
  }
  &__primary-links {
    margin-top: spacing(3);
    margin-bottom: spacing(2);
    .cmp-text {
      gap: spacing(2);
      max-width: 100%;

      p {
        font-weight: 800;
        font-size: rem-calc(16);
        line-height: rem-calc(16);
        margin-bottom: 0;
        margin-top: 0;

        @include breakpoint-down(tablet) {
          font-size: rem-calc(14);
          line-height: rem-calc(14);
        }
      }
    }
  }
}
