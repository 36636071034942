.container {
  &.center {
    width: auto;
    padding: 0 spacing(2);

    .cmp-container {
      width: 100%;
      max-width: rem-calc(1120);
      margin: 0 auto;

      @include breakpoint-down(tablet) {
        max-width: rem-calc(640);
      }
    }
  }
}
