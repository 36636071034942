//== Typography

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: normal;
  margin: rem-calc(2.5) 0;
}

h1 {
  font-family: "TCCC-UnityCondensed-Bold";
  font-size: rem-calc(96);
  line-height: 95%;
  text-transform: uppercase;

  @include breakpoint-down(mobile) {
    font-size: rem-calc(72);
  }
}

h2 {
  font-family: "TCCC-UnityCondensed-Bold";
  font-size: rem-calc(72);
  line-height: 95%;
  text-transform: uppercase;

  @include breakpoint-down(mobile) {
    font-size: rem-calc(56);
  }
}

h3 {
  font-family: "TCCC-UnityText-Black";
  font-size: rem-calc(38);
  line-height: 120%;

  @include breakpoint-down(mobile) {
    font-size: rem-calc(28);
  }
}

h4 {
  font-family: "TCCC-UnityText-Black";
  font-size: rem-calc(32);
  line-height: 130%;

  @include breakpoint-down(mobile) {
    font-size: rem-calc(24);
  }
}
