.search {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $color-background-white;
    height: rem-calc(300);
    &__input-wrapper {
        position: relative;
        max-width: rem-calc(730);
        width: 100%;
        display: inline-block;
        padding-left: rem-calc(16);
        padding-right: rem-calc(16);
    }
    &__input {
        width: -webkit-fill-available;
        height: rem-calc(44);
        border-radius: rem-calc(999);
        border: 1px solid $color-border-black;
        font-family: "TCCC-UnityHeadline-black";
        font-weight: 800;
        font-size: rem-calc(24);
        line-height: rem-calc(33.6);
        padding: rem-calc(13) rem-calc(60) rem-calc(13) rem-calc(32);
        &::placeholder {
            color: $color-text-grey-500;
        }
        &:focus-visible {
            outline: rem-calc(4) solid $color-border-blue;
        }
    }
    &__no-results-headline {
        font-family: "TCCC-UnityText-Bold";
        font-weight: 800;
        font-size: rem-calc(24);
        line-height: rem-calc(33.6);
        width: 100%;
        text-align: center;
        text-transform: none;
        padding: rem-calc(32) 0;
        @include breakpoint-down(mobile) {
            font-size: rem-calc(20);
            line-height: rem-calc(28);
        }
    }
    &__no-results-copy {
        font-family: 'TCCC-UnityText-Regular';
        font-size: rem-calc(16);
        font-weight: 400;
        color: $color-text-black;
        line-height: rem-calc(24);
        text-align: center;
        width: 100%;
        margin-bottom: rem-calc(30);
        @include breakpoint-down(mobile) {
            line-height: rem-calc(21);
        }
    }
    &__button {
        position: absolute;
        z-index: 100;
        right: rem-calc(55);
        top: rem-calc(25);
        padding: 0;
        margin: 0;
        background-image: url('data:image/svg+xml,%3Csvg xmlns%3D%22http%3A//www.w3.org/2000/svg%22 width%3D%2224%22 height%3D%2225%22 viewBox%3D%220 0 24 25%22 fill%3D%22none%22%3E%3Cpath d%3D%22M15.4142 5.84346C15.0237 5.45294 14.3905 5.45293 14 5.84346C13.6095 6.23398 13.6095 6.86715 14 7.25767L17.5858 10.8435H3C2.44772 10.8435 2 11.2912 2 11.8435C2 12.3957 2.44772 12.8435 3 12.8435H17.716L13.9435 16.9304C13.5689 17.3362 13.5942 17.9689 14 18.3435C14.4058 18.7181 15.0385 18.6928 15.4131 18.2869L21.3865 11.8157L15.4142 5.84346Z%22 fill%3D%22black%22/%3E%3C/svg%3E');
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        background-color: transparent;
        width: auto;
        height: rem-calc(24);
        width: rem-calc(24);
        border: none;
        cursor: pointer;
        &-close {
            background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2224%22%20height%3D%2225%22%20viewBox%3D%220%200%2024%2025%22%20fill%3D%22none%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M6.79529%205.43818C6.38464%205.03574%205.71885%205.03574%205.30821%205.43818C4.89756%205.84061%204.89756%206.49308%205.30821%206.89551L10.6559%2012.1362L5.30799%2017.3772C4.89734%2017.7796%204.89734%2018.4321%205.30799%2018.8345C5.71863%2019.2369%206.38442%2019.2369%206.79507%2018.8345L12.143%2013.5936L17.2049%2018.5542C17.6155%2018.9566%2018.2813%2018.9566%2018.692%2018.5542C19.1026%2018.1517%2019.1026%2017.4993%2018.692%2017.0968L13.6301%2012.1362L18.6917%207.17586C19.1024%206.77343%2019.1024%206.12096%2018.6917%205.71853C18.2811%205.3161%2017.6153%205.3161%2017.2046%205.71853L12.143%2010.6789L6.79529%205.43818Z%22%20fill%3D%22black%22/%3E%3C/svg%3E");
        }
    }
    &__results {
        max-width: rem-calc(1120);
        margin: 0 auto;
        width: auto;
        padding-left: rem-calc(16);
        padding-right: rem-calc(16);
        h3 {
            font-family: "TCCC-UnityText-Bold";
            font-weight: 800;
            font-size: rem-calc(16);
            margin-bottom: rem-calc(32);
        }
    }
    &__result {
        margin-bottom: rem-calc(32);
        display: flex;
        flex-direction: row;
        width: auto;
        gap: rem-calc(32);
        text-decoration: none;
        outline-offset: 16px;
        @include breakpoint-down(mobile) {
            flex-direction: column;
            margin-bottom: rem-calc(16);
            outline-offset: 8px;
        }
        img {
            display: block;
            width: rem-calc(256);
            max-width: 100%;
            height: auto;
            align-self: flex-start;
            @include breakpoint-down(mobile) {
                width: 37.5%;
            }
        }
        &-info {
            flex-direction: column;
            h3 {
                font-family: "TCCC-UnityText-Bold";
                font-size: rem-calc(24);
                line-height: rem-calc(33.6);
                margin: 0;
                @include breakpoint-down(mobile) {
                    font-size: rem-calc(20);
                    line-height: rem-calc(28);
                }
            }
            p {
                margin-top: 0;
                max-height: 11rem;
                line-height: rem-calc(24);
                font-size: remp-calc(16);
                font-weight: 400;
                color: $color-text-grey;
                padding: 0;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                @include breakpoint-down(mobile) {
                    line-height: rem-calc(24);
                    font-size: rem-calc(14);
                }
            }
            &-tag {
                @include tag;
            }
        }
    }
    &__results-info {
        display: flex;
        justify-content: space-between;
        @include breakpoint-down(mobile) {
            flex-direction: column;
        }
        &-pages {
            font-size: rem-calc(16);
            font-weight: 400;
            @include breakpoint-down(mobile) {
                width: 100%;
                text-align: center;
            }
        }
        &-paginator {
            display: flex;
            align-items: center;
            justify-content: center;
            &-page {
                border: none;
                background: transparent;
                font-size: rem-calc(14);
                font-weight: 400;
                font-family: 'TCCC-UnityText-Regular';
                cursor: pointer;
                &.active {
                    font-family: "TCCC-UnityText-Bold";
                    font-weight: 800;
                    text-decoration: none;
                }
            }
            &-prev {
                display: inline-block;
                width: 40px;
                height: 16px;
                margin-right: 20px;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='45' height='45' viewBox='0 0 45 45' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M26.2484 15.7347C25.8752 15.3276 25.2426 15.3001 24.8355 15.6733L18.7287 21.2712C18.0605 21.8837 18.0605 22.9371 18.7287 23.5496L24.8355 29.1476C25.2426 29.5208 25.8752 29.4932 26.2484 29.0861C26.6216 28.679 26.5941 28.0464 26.1869 27.6733L20.4457 22.4104L26.1869 17.1476C26.5941 16.7744 26.6216 16.1418 26.2484 15.7347Z' fill='black'/%3E%3C/svg%3E");
                background-repeat: no-repeat;
                background-position: center;
                background-size: 45px 45px;
                cursor: pointer;
                background-color: transparent;
                border: none;
                transition: background-color 0.3s ease, transform 0.2s ease;
            }
            &-next {
                display: inline-block;
                width: 40px;
                height: 17px;
                margin-left: 20px;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='25' height='25' viewBox='0 0 25 25' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8.49039 5.73468C8.86358 5.32756 9.49615 5.30006 9.90327 5.67325L16.0101 11.2712C16.6783 11.8837 16.6783 12.9371 16.0101 13.5496L9.90327 19.1476C9.49615 19.5208 8.86358 19.4932 8.49039 19.0861C8.1172 18.679 8.1447 18.0464 8.55182 17.6733L14.2931 12.4104L8.55182 7.14756C8.1447 6.77437 8.1172 6.1418 8.49039 5.73468Z' fill='black'/%3E%3C/svg%3E");
                background-repeat: no-repeat;
                background-position: center;
                background-size: 25px 25px;
                cursor: pointer;
                background-color: transparent;
                border: none;
                transition: background-color 0.3s ease, transform 0.2s ease;
            }

        }
    }
}