.cmp-button {
  background-color: $color-background-white;
  border: rem-calc(2) solid $color-border-black;
  border-radius: rem-calc(40);
  color: $color-text-black;
  cursor: pointer;
  display: block;
  height: fit-content;
  max-width: rem-calc(327);
  padding: spacing(1.5) spacing(3);
  text-decoration: none;
  width: fit-content;
  box-sizing: border-box;

  &:hover {
    border-color: $color-border-grey-500;
    color: $color-text-grey-500;
  }

  &:focus {
    outline: rem-calc(4) solid $color-border-blue;
    outline-offset: rem-calc(-5);
    border-color: $color-border-blue;
  }

  &:disabled {
    color: $color-text-grey-300;
    cursor: not-allowed;
  }

  &::after {
    margin-left: spacing(1);
  }

  &__text {
    font-family: "TCCC-UnityText-Bold";
    text-decoration: none;
    text-transform: capitalize;
  }
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-primary {
  .cmp-button {
    &:hover {
      border-color: $color-border-grey-500;
    }

    &:disabled {
      border-color: $color-border-grey-300;
    }
  }
}

.button-secondary {
  .cmp-button {
    align-items: center;
    border-color: transparent;
    display: flex;
    @include icon($iconArrowRight, 22, 20);
  }
}

.button.center {
  display: flex;
  justify-content: center;
  align-items: center;
}