#search-input {
    .search-input {
        max-width: 66%;
        margin: rem-calc(40) auto 0 auto;
        position: relative;
        @include breakpoint-down(tablet) {
            max-width: 100%;
        }
        &__input-wrapper {
            position: relative;
            max-width: rem-calc(730);
            width: 100%;
            display: inline-block;
            padding-left: rem-calc(16);
            padding-right: rem-calc(16);
        }
        &__input {
            width: -webkit-fill-available;
            height: rem-calc(44);
            border-radius: rem-calc(999);
            border: 1px solid $color-border-black;
            font-family: "TCCC-UnityHeadline-black";
            font-weight: 800;
            font-size: rem-calc(24);
            line-height: rem-calc(33.6);
            padding: rem-calc(8) rem-calc(60) rem-calc(8) rem-calc(32);
            &:focus-visible {
                outline: rem-calc(4) solid $color-border-blue;
            }
            &::placeholder {
                color: $color-text-grey-500;
            }
            &:hover {
                box-shadow: 2px 2px 4px 0px #00000080;
            }
        }
        &__button {
            position: absolute;
            z-index: 100;
            right: rem-calc(30);
            top: rem-calc(20);
            padding: 0;
            margin: 0;
            background-image: url('data:image/svg+xml,%3Csvg xmlns%3D%22http%3A//www.w3.org/2000/svg%22 width%3D%2224%22 height%3D%2225%22 viewBox%3D%220 0 24 25%22 fill%3D%22none%22%3E%3Cpath d%3D%22M15.4142 5.84346C15.0237 5.45294 14.3905 5.45293 14 5.84346C13.6095 6.23398 13.6095 6.86715 14 7.25767L17.5858 10.8435H3C2.44772 10.8435 2 11.2912 2 11.8435C2 12.3957 2.44772 12.8435 3 12.8435H17.716L13.9435 16.9304C13.5689 17.3362 13.5942 17.9689 14 18.3435C14.4058 18.7181 15.0385 18.6928 15.4131 18.2869L21.3865 11.8157L15.4142 5.84346Z%22 fill%3D%22black%22/%3E%3C/svg%3E');
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            background-color: transparent;
            width: auto;
            height: rem-calc(24);
            width: rem-calc(24);
            border: none;
            cursor: pointer;
        }
    }
}