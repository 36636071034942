
//== Variables

@import 'site/variables';
@import 'site/grid';
@import 'site/mixins';
@import 'site/functions';

//== Site global

@import 'npm:normalize.css';
@import 'site/fonts';
@import 'site/base';
@import 'site/typography';

//== Core components

@import 'components/text/_text.scss';

@import 'components/title/_title.scss';
@import 'components/title/_title_styles.scss';

@import 'components/image/_image.scss';
@import 'components/image/_image_caption.scss';
@import 'components/image/_image_standard.scss';

@import 'components/separator/_separator.scss';

@import 'components/progressbar/_progressbar.scss';

@import 'components/icons/_icons.scss';

@import 'components/button/_button.scss';

@import 'components/teaser/_teaser.scss';
@import 'components/teaser/_teaser_dark.scss';
@import 'components/teaser/_teaser_text_at_bottom.scss';
@import 'components/teaser/_teaser_text_at_left.scss';
@import 'components/teaser/_teaser_text_at_right.scss';

@import 'components/download/_download.scss';


//== Lists and Navigation components

@import 'components/list/_list.scss';

@import 'components/navigation/_navigation.scss';

@import 'components/languagenavigation/_languagenavigation.scss';

@import 'components/breadcrumb/_breadcrumb.scss';
@import 'components/breadcrumb/_breadcrumb_standard.scss';

@import 'components/search/_search.scss';
@import 'components/search-input/_search-input.scss';

//== Container components

@import 'components/container/_container.scss';
@import 'components/container/_container_background.scss';
@import 'components/container/_container_center.scss';
@import 'components/container/_container_content.scss';
@import 'components/container/_container_dark_theme.scss';
@import 'components/container/_container_full-width.scss';
@import 'components/container/_container_main.scss';
@import 'components/container/_container_small-center';
@import 'components/container/_container_splits.scss';

@import 'components/carousel/_carousel.scss';

@import 'components/tabs/_tabs.scss';
@import 'components/tabs/_tabs_horizontal.scss';
@import 'components/tabs/_tabs_vertical.scss';

@import 'components/accordion/_accordion.scss';

//== Fragments (CF + XF) components

@import 'components/contentfragment/_contentfragment.scss';
@import 'components/contentfragmentlist/_contentfragmentlist.scss';

//== Form components

@import 'components/form/_form.scss';
@import 'components/form-options/_form-options.scss';

//== Embed components

@import 'components/embed/_embed.scss';
@import 'components/embed/_embed-third-party-experience.scss';

@import 'components/pdfviewer/_pdfviewer.scss';

@import 'components/socialmediasharing/_social_media_sharing.scss';
@import 'components/header/_header.scss';

@import 'components/footer/_footer.scss';
@import 'components/teaser/_teaser.scss';

@import 'components/article-teasers/_article-teasers.scss';
@import 'components/all-articles/_all-articles.scss';
@import 'components/article-tags/_article-tags.scss';

