.container {
  &.small-center {
    width: auto;
    padding: 0 spacing(3);

    > .cmp-container {
      max-width: rem-calc(730);
      margin: 0 auto;
    }
  }
}
