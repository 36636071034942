//== Font

$font-family: "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-family-title: "Source Serif Pro", "Helvetica Neue", Helvetica, Arial,
  sans-serif;
$font-size: 16;
$font-height: 20px;

//== Color

// Base Colors
$colors-black: #000000;
$colors-white: #ffffff;
$colors-blue-500: #0500f4;
$colors-blue-600: #120791;
$colors-blue-700: #0a044d;
$colors-grey: #505050;
$colors-grey-100: #f5f5f5;
$colors-grey-300: #d4d4d4;
$colors-grey-400: #9b9b9b;
$colors-grey-500: #6e6e6e;
$colors-green-100: #f1fcf5;
$colors-green-500: #11FF37;
$colors-green-700: #4B9C69;
$colors-green-800: #34774d;
$colors-red: #c30007;
$colors-orange: #ffb400;

$color-background-white: $colors-white;
$color-background-black: $colors-black;
$color-background-orange: $colors-orange;
$color-background-grey-100: $colors-grey-100;
$color-background-green-100: $colors-green-100;
$color-background-green-700: $colors-green-700;
$color-background-green-800: $colors-green-800;

$color-border-black: $colors-black;
$color-border-white: $colors-white;
$color-border-grey-300: $colors-grey-300;
$color-border-grey-400: $colors-grey-400;
$color-border-grey-500: $colors-grey-500;
$color-border-green-500: $colors-green-500;
$color-border-green-800: $colors-green-800;
$color-border-blue: $colors-blue-500;

$color-text-black: $colors-black;
$color-text-white: $colors-white;
$color-text-grey: $colors-grey;
$color-text-grey-100: $colors-grey-100;
$color-text-grey-300: $colors-grey-300;
$color-text-grey-400: $colors-grey-400;
$color-text-grey-500: $colors-grey-500;
$color-text-green-800: $colors-green-800;
$color-text-blue-600: $colors-blue-600;
$color-text-blue-700: $colors-blue-700;
$color-text-red: $colors-red;

$color-link: #2020e0;
$color-accent: #2954ff;
$color-accent-lighter: #436eff;
$color-accent-darker: #103be6;
$color-shade-1: #eceff1;
$color-shade-2: #cfd8dc;
$color-shade-3: #607d8b;
$color-shade-4: #455a64;

$error: #d7373f;
$error-darker: #be1e26;
$success: #268e6c;
$success-darker: #0d7553;

$color-border-black: $colors-black;
$color-border-grey-400: $colors-grey-400;

$color-text-primary: $colors-black;
$color-text-secondary: $colors-white;

$color-shadow: rgba(0, 0, 0, 0.16);
$color-transparent: rgba(0, 0, 0, 0);

// Breakpoints
$breakpoint-tablet: 769px; // 768 + 1 so that 768 is still tablet
$breakpoint-mobile: 577px;

$grid-breakpoints: (
  xs: 0,
  sm: $breakpoint-mobile,
  mobile: $breakpoint-mobile,
  md: $breakpoint-tablet,
  tablet: $breakpoint-tablet,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
) !default;

$spacing-base: 8;
