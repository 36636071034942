.container {
  .split-25-75 {
    > .cmp-container {
      display: flex;
      flex-direction: row;
      gap: spacing(4);
      @include breakpoint-down(tablet) {
        flex-direction: column;
      }

      > :first-child {
        width: 25%;
        @include breakpoint-down(tablet) {
          width: 100%;
        }
      }

      > :last-child {
        width: 75%;
        @include breakpoint-down(tablet) {
          width: 100%;
        }
      }
    }
  }

  .split-75-25 {
    > .cmp-container {
      display: flex;
      flex-direction: row;
      gap: spacing(4);
      @include breakpoint-down(tablet) {
        flex-direction: column;
      }

      > :first-child {
        width: 75%;
        @include breakpoint-down(tablet) {
          width: 100%;
        }
      }

      > :last-child {
        width: 25%;
        @include breakpoint-down(tablet) {
          width: 100%;
        }
      }
    }
  }

  .split-50-50 {
    > .cmp-container {
      display: flex;
      flex-direction: row;
      gap: spacing(4);
      @include breakpoint-down(tablet) {
        flex-direction: column;
      }

      > div {
        width: 50%;
        @include breakpoint-down(tablet) {
          width: 100%;
        }
      }
    }
  }

  .split-33-33-33 {
    > .cmp-container {
      display: flex;
      flex-direction: row;
      gap: spacing(4);
      @include breakpoint-down(tablet) {
        flex-direction: column;
      }

      > div {
        width: 33.33%;
        @include breakpoint-down(tablet) {
          width: 100%;
        }
      }
    }
  }
}
