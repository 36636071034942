.cmp-teaser {
  
  &__tags {
    margin-bottom: spacing(2);
  }

  &__tag {
    @include tag;
  }

  &__title,
  &__title-link {
    color: $color-text-black;
    font-family: "TCCC-UnityHeadline-black";
    font-size: rem-calc(24);
    font-style: normal;
    font-weight: 800;
    line-height: 140%;
    text-decoration: none;
    max-height: 3em;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin-left: 0;
  }

  &__description {
    color: $color-text-grey;
    font-family: "TCCC-UnityText-Regular";
    font-size: rem-calc(16);
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    p {
      margin-top: 0;
      max-height: 11rem;
      line-height: rem-calc(22);
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }

  &__content {
    padding: spacing(4) spacing(4) spacing(16) spacing(4);
    text-align: left;
    @include breakpoint-down(tablet) {
      padding: spacing(2) spacing(2) spacing(13) spacing(2);
    }
  }

  &__cta,
  &__action-link {
    position: absolute;
    bottom: rem-calc(16);
    display: flex;
    color: $color-text-black;
    text-align: right;
    font-family: "TCCC-UnityText-black";
    font-size: rem-calc(16);
    font-style: normal;
    font-weight: 800;
    line-height: 100%;
    margin-top: spacing(3);
    text-transform: capitalize;
    text-decoration: none;
    @include icon($iconArrowRight, 22, 20);
    &:after {
      display: inline-block;
      margin-left: rem-calc(5);
    }
  }

  &__pretitle {
    font-family: "TCCC-UnityText-black";
    font-size: rem-calc(12);
    font-style: normal;
    font-weight: 800;
    line-height: 120%;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    text-decoration: none;
    color: $color-text-grey;
  }

  .cmp-image {
    margin-top: 0;
  }
  img {
    width: 100%;
    aspect-ratio: 4/3;
    border-top-left-radius: rem-calc(10);
    border-top-right-radius: rem-calc(10);
  }
}

.teaser {
  text-decoration: none;
  padding: spacing(4);
  &.component {
    padding: 0;
    height: 100%;
  }
}

.cmp-teaser,
.cmp-teaser__link,
.cmp-teaser__container {
  position: relative;
  display: flex;
  height: 95%;
  overflow: visible;
  justify-content: flex-end;
  flex-direction: column-reverse;
  background-color: $color-background-white;
  border-radius: rem-calc(10);
  box-shadow: 0 rem-calc(3) rem-calc(6) 0 rgba(0, 0, 0, 0.1);
  text-decoration: none;
  &.component {
    border-radius: none;
    box-shadow: none;
  }
}

.cmp-teaser.component {
  .cmp-teaser__title,
  .cmp-teaser__title-link,
  .cmp-teaser__description {
    max-height: none;
    overflow: visible;
    text-overflow: clip;
    display: block;
    -webkit-line-clamp: unset;
    text-transform: none;
  }
}
