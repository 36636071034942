.all-articles {

  &__filter-copy {
    margin-bottom: spacing(3);
    span {
        color: $color-text-black;
        font-size: rem-calc(16);

    }
  }  

  &__tags {
    margin-bottom: spacing(3);
  }

  &__tag {
    cursor: pointer;
    @include tag;
    font-size: rem-calc(24);
    padding: spacing(1) spacing(2);
    margin-right: spacing(2);
    font-style: normal;
    font-weight: 800;
    line-height: 140%;
    border-width: rem-calc(2);
    text-transform: none;
    background: $color-background-white;
    &:hover {
      background-color: $color-background-green-100;
    }
    &--no-pointer {
      cursor: default;
      &:hover {
        background-color: $color-background-green-700;
      }
    }
  }
}
