.separator {
  height: rem-calc(24);
  margin: 0 auto;
  position: relative;

  &.left {
    margin-left: 0;
    margin-right: 0;
  }

  // Desktop
  &.x-small {
    height: rem-calc(24);
  }
  &.small {
    height: rem-calc(32);
  }
  &.medium {
    height: rem-calc(48);
  }
  &.large {
    height: rem-calc(64);
  }
  &.x-large {
    height: rem-calc(120);
  }

  &.gradient-small,
  &.gradient-medium,
  &.gradient-large {
    &::after {
      @include rainbow-border;
      margin: rem-calc(24) 0;
    }
  }

  &.gradient-small {
    width: 33.33%;
  }

  &.gradient-medium {
    width: 83.33%;
  }

  &.gradient-large {
    width: 100%;
  }

  &.full-width-on-mobile {
    @include breakpoint-down(mobile) {
      width: 100%;
    }
  }

  // Tablet
  @include breakpoint-down(tablet) {
    &.x-small {
      height: rem-calc(24);
    }
    &.small {
      height: rem-calc(32);
    }
    &.medium {
      height: rem-calc(32);
    }
    &.large {
      height: rem-calc(56);
    }
    &.x-large {
      height: rem-calc(80);
    }
  }

  // Mobile
  @include breakpoint-down(mobile) {
    &.x-small {
      height: rem-calc(16);
    }
    &.small {
      height: rem-calc(24);
    }
    &.medium {
      height: rem-calc(32);
    }
    &.large {
      height: rem-calc(56);
    }
    &.x-large {
      height: rem-calc(56);
    }
  }
}

.cmp-separator__horizontal-rule {
  border: none;
  margin: 0;
}
