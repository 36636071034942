.image {
  .cmp-image {
    &__image {
      display: block;
      height: auto;
      width: 100%;
    }
  }
  &.round {
    .cmp-image {
      &__image {
        border-radius: rem-calc(10);
      }
    }
  }
}