.container {
  &.container-bg-black {
    > .cmp-container {
      background-color: $color-background-black;
      .cmp-text {
        color: $color-text-grey-100;
      }
      h1, h2, h3, h4, h5, h6 {
        color: $color-text-white;
      }

      .cmp-button {
        background-color: transparent;
        border-color: $color-border-white;
        color: $color-text-white;

        &:focus {
          border-color: $color-border-green-500;
          outline: rem-calc(4) solid $color-border-green-500;
        }
      }

      .button-primary {
        .cmp-button {
          border: rem-calc(2) solid $color-border-white;

          &:focus {
            border-color: $color-border-black;
          }
        }
      }

      .button-secondary {
        .cmp-button {
          border-color: $color-border-black;
        }
      }
    }
  }

  &.container-bg-white {
    > .cmp-container {
      background-color: $color-background-white;
      .cmp-text {
        color: $color-text-grey;
      }
      h1, h2, h3, h4, h5, h6 {
        color: $color-text-black;
      }
      .cmp-button {
        background-color: transparent;
      }
    }
  }

  &.container-bg-transparent {
    > .cmp-container {
      background-color: transparent;
      .cmp-text {
        color: $color-text-grey;
      }
      h1, h2, h3, h4, h5, h6 {
        color: $color-text-black;
      }
      .cmp-button {
        background-color: transparent;
      }
    }
  }

  &.container-bg-gradient {
    > .cmp-container {
      background: linear-gradient(to right, #F40000, #FF5A00, #FF8700, #FFB400, #78E6A0, #82E1FF);
      .cmp-text, h1, h2, h3, h4, h5, h6 {
        color: $color-text-white;
      }
    }
  }

  &.container-bg-orange {
    > .cmp-container {
      background: $color-background-orange;
      .cmp-text {
        color: $color-text-grey;
      }
      h1, h2, h3, h4, h5, h6 {
        color: $color-text-black;
      }

      .cmp-button {
        background-color: transparent;
      }
      .cmp-button {
        background-color: transparent;
      }
    }
  }

  &.container-bg-grey {
    > .cmp-container {
      background: $color-background-grey-100;
      .cmp-text {
        color: $color-text-grey;
      }
      h1, h2, h3, h4, h5, h6 {
        color: $color-text-black;
      }
      .cmp-button {
        background-color: transparent;
      }
    }
  }
}
