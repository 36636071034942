.cmp-carousel {
  .adaptiveImage {
    .cmp-adaptive-image {
      aspect-ratio: 2;

      @include breakpoint-down(tablet) {
        aspect-ratio: 0.75;
      }
    }

    .cmp-image__image {
      border-radius: rem-calc(20);
      display: block;
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }

  .swiper-pagination.swiper-pagination-bullets {
    display: flex;
    justify-content: center;
    position: static;
    transform: none;
    z-index: auto;

    .swiper-pagination-bullet {
      background-color: $color-background-white;
      border: rem-calc(1) solid $color-border-grey-400;
      border-radius: 50%;
      cursor: pointer;
      height: rem-calc(18);
      margin-top: spacing(3);
      margin-inline: spacing(0.5);
      opacity: 1;
      padding: 0;
      width: rem-calc(18);

      &.swiper-pagination-bullet-active {
        align-items: center;
        border-color: $color-border-black;
        display: flex;
        justify-content: center;

        &::after {
          background-color: $color-background-black;
          border-radius: rem-calc(10);
          content: "";
          height: rem-calc(10);
          width: rem-calc(10);
        }
      }
    }
  }
}
