.article-tags {

  &__tags {
    max-width: spacing(63);
    padding-left: spacing(1);
    padding-right: spacing(1);
    width: auto;
    text-align: center;
    margin: 0 auto;
  }

  &__tag {
    @include tag;
  }
}
