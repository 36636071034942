// Minimum breakpoint width. Null for the smallest (first) breakpoint.
//
//    >> breakpoint-min(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    576px
@function breakpoint-min($name, $breakpoints: $grid-breakpoints) {
  $min: map-get($breakpoints, $name);

  @return if($min !=0, $min, null);
}

// Maximum breakpoint width.
// The maximum value is reduced by 0.02px to work around the limitations of
// `min-` and `max-` prefixes and viewports with fractional widths.
// See https://www.w3.org/TR/mediaqueries-4/#mq-min-max
// Uses 0.02px rather than 0.01px to work around a current rounding bug in Safari.
// See https://bugs.webkit.org/show_bug.cgi?id=178261
//
//    >> breakpoint-max(md, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    767.98px
@function breakpoint-max($name, $breakpoints: $grid-breakpoints) {
  $max: map-get($breakpoints, $name);

  @return if($max and $max > 0, $max - 0.02, null);
}

// Media of at least the minimum breakpoint width. No query for the smallest breakpoint.
// Makes the @content apply to the given breakpoint and wider.
@mixin breakpoint-up($name, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($name, $breakpoints);

  @if $min {
    @media screen and (min-width: $min) {
      @content;
    }
  } @else {
    @content;
  }
}

// Media of at most the maximum breakpoint width. No query for the largest breakpoint.
// Makes the @content apply to the given breakpoint and narrower.
@mixin breakpoint-down($name, $breakpoints: $grid-breakpoints) {
  $max: breakpoint-max($name, $breakpoints);

  @if $max {
    @media screen and (max-width: $max) {
      @content;
    }
  } @else {
    @content;
  }
}

@mixin rainbow-border {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: rem-calc(4);
  background: linear-gradient(
    to right,
    #f40000,
    #ff5a00,
    #ff8700,
    #ffb400,
    #78e6a0,
    #82e1ff
  );
}

@mixin tag {
  display: inline-block;
  border: rem-calc(1) solid $color-border-green-800;
  border-radius: rem-calc(10);
  padding: spacing(0.5) spacing(2);
  margin-right: spacing(1);
  width: fit-content;
  margin-bottom: spacing(1);
  background-color: $color-background-green-100;
  color: $color-text-green-800;
  text-align: center;
  font-family: TCCC-UnityText-black;
  font-size: rem-calc(12);
  font-style: normal;
  font-weight: 800;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  &--active {
    background-color: $color-background-green-800;
    color: $color-text-white;
  }
}
